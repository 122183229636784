<template>
  <div class="boutique-detail-wrap main-wrapper">
    <!--  v-if="gameDetail && gameDetail.gameInfoEntity" -->
    <!-- 商品信息 -->
    <div class="breadcrumb-box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/boutique' }">
          <!-- {{ gameDetail.gameInfoEntity.Name }} -->
          PUBG优选账号
        </el-breadcrumb-item>
        <el-breadcrumb-item>详情介绍</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="game-info" v-if="gameDetail">
      <div class="left">
        <img :src="gameDetail.Img" alt="" />
      </div>
      <div class="right">
        <div class="title" v-html="gameDetail.Title"></div>
        <div class="tags" v-if="gameDetail.Labels">
          <span v-for="(i, o) in gameDetail.Labels.split(',')" :key="o" v-show="o < 5">{{ i }}</span>
        </div>
        <!-- <div class="introduction">
          <span>游戏介绍：</span>
          绝地求生(PLAYERUNKNOWN’S
          BATTLEGROUNDS)是战术竞技类游戏，每一局游戏将有100名玩家参与，他们将被投放在绝地岛的上空，游戏开始跳伞时所有人都一无所有。
        </div> -->
        <!-- <div class="reminder">
          <span>温馨提示：</span>
          禁止使用外挂或第三方插件, 否则永久封号
        </div> -->
        <div class="careful">
          <span>注意：</span>
          登录后steam账号出现红色信封或黄色信封不影响您的游戏体验
        </div>
        <div class="btn pic-item">
          <el-button :class="[disabled ? 'dis' : '']" :disabled="disabled" @click="start"
            v-if="gameDetail.Status !== '4'">{{ btnText }}</el-button>
          <el-button class="occupy" v-else>占用中，请稍后</el-button>

          <!-- <el-button v-if="item.Status !== '4'" @click="toDetail(item)"
              >开始玩游戏</el-button
            >
            <el-button class="occupy" v-else>占用中，请稍后</el-button> -->
        </div>
      </div>
    </div>

    <!-- 详情内容 -->
    <div class="detail-content" v-if="gameDetail">
      <div class="title">1分钟了解蚂蚁特权</div>
      <div class="html-content" v-html="gameDetail.Explanation"></div>
    </div>

    <!-- 游戏存档记录 -->
    <el-dialog custom-class="archive-dialog" title="选择存档" :visible.sync="isShow" width="536px" center
      :close-on-click-modal="false">
      <!-- <div class="last-game">
        <div class="title" v-if="JSON.stringify(perfectArchive) !== '{}'">
          使用游戏的完美存档
        </div>
        <div class="item" v-if="JSON.stringify(perfectArchive) !== '{}'">
          <el-radio
            v-model="radio1"
            label="0"
            border
            @change="handleChangeRadio(perfectArchive.GameArchiveInfoZipURL)"
            >{{ perfectArchive.Describe }}</el-radio
          >
        </div>
        <div class="title2" v-if="gameList.length">
          使用我的云存档
          <span>（最近一次存档时间：{{ this.gameList[0].SaveTime }}）</span>
        </div>
        <div class="item" v-for="(item, index) in gameList" :key="index">
          <el-radio
            v-model="radio1"
            :label="index + 1 + ''"
            border
            @change="handleChangeRadio(item.GameArchiveInfoZipURL)"
          >
            {{ item.Describe }}
            <span>{{ item.SaveTime }}</span>
          </el-radio>
        </div>

        <div class="btn-box-center">
          <a href="javascript:;" class="random-account" @click="randomAccount"
            >不取存档, 直接玩游戏</a
          >
          <a href="javascript:;" class="start-game" @click="startArchiveGame"
            >取存档玩游戏</a
          >
        </div>
      </div> -->
    </el-dialog>

    <!-- 会员开通提示窗 -->
    <el-dialog title="开通会员" :visible.sync="centerDialogVisible" width="400px" center top="30vh"
      :close-on-click-modal="false">
      <span>您还不是会员,只有成为会员才可以免费玩所有游戏！</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="OpeningMmber">开通会员</el-button>
        <el-button @click="centerDialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 开通会员弹窗 -->
    <MembersBuy :isShow="isShowMemberDialog" :slectActive="active" @changeShow="showDialogVisible" />

    <!-- 启动其他游戏 -->
    <el-dialog title="温馨提示：" :visible.sync="dialogVisible" width="400px" center :close-on-click-modal="false">
      <span class="text-content">{{ errorStr }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toHome">去选其他精品账号</el-button>
      </span>
    </el-dialog>

    <!-- 游戏时长提示窗 -->
    <el-dialog title="提示" :visible.sync="gameTimeVisible" width="400px" center top="30vh" :close-on-click-modal="false">
      <span style="line-height: 24px" v-if="gameDetail && seniorGame.length">
        由于精品账号数量有限，您还需要去体验绝地求生豪华版<span style="color: red">{{ parseInt(seniorGame) - Math.floor(getTimer)
        }}</span>小时才可以体验精品账号
      </span>
      <p style="margin-top: 10px; text-align: center; font-size: 16px">
        您当前时长为<span style="color: red"> {{ Math.floor(getTimer) }} </span>小时！
      </p>

      <div style="text-align: center; margin-top: 10px">
        <el-button type="primary" @click="toJdqs">现在去玩</el-button>
      </div>
    </el-dialog>

    <!-- 登录游戏进度条 -->
    <el-dialog :visible.sync="progressBarDialog" width="450px" :before-close="progressBarClose" :show-close="false"
      custom-class="progressBarDialog" :close-on-click-modal="false" v-if="gameDetail">
      <div class="ctx">
        <div class="gameName">{{ gameDetail.GameName }}</div>
        <img class="gameImg" :src="gameDetail.Img" width="100%" alt="" />
        <el-progress color="#ff3636" :percentage="progressNum"></el-progress>
        <p>{{ progressText }}</p>
      </div>
    </el-dialog>

    <!-- 绝地高端游戏实名认证 -->
    <AuthForGame ref="authForGame" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  GetNavigationList,
  GetGameAccountLogin,
  GetNetbarOnlineNumber,
  GetConsumerGameTimeByHaoYou,
} from "@/api";
import MembersBuy from "./components/MembersBuy.vue";
import AuthForGame from "./components/AuthForGame.vue";
import cMethods from "@/util/cMethods.js";

export default {
  components: {
    MembersBuy,
    AuthForGame,
  },
  computed: {
    ...mapGetters([
      "userInfo",
      "netBarId",
      "netBarName",
      "netBarVIP",
      "isLogin",
      "getIsAuth",
      "getHighGames",
      "getTimer",
    ]),
  },
  data() {
    return {
      gameDetail: null,
      disabled: false,
      isShowMemberDialog: false,
      gameTimeVisible: false,
      isShowMemberDialog: false,
      active: 0,
      isShow: false,
      perfectArchive: {},
      gameObjData: {},
      Tel: "",
      centerDialogVisible: false,
      onlineNumber: false,
      dialogVisible: false,
      errorStr: "",
      radio1: "0",
      ArchiveURL: "", // 存档地址
      seniorGame: [],
      gameList: [],
      progressBarDialog: false,
      progressNum: 0,
      progressText: "",
      btnText: "登录游戏",
    };
  },
  mounted() {
    this._GetNavigationList();
    if (sessionStorage.getItem("detail")) {
      this.gameDetail = JSON.parse(sessionStorage.getItem("detail"));
      if (this.gameDetail && this.gameDetail.IsRepair == "True") {
        this.disabled = true;
        this.btnText = "游戏维护中";
      } else {
        this.disabled = false;
        this.btnText = "登录游戏";
      }
      // console.log(this.gameDetail.Explanation);
      this.gameDetail.Explanation = this.gameDetail.Explanation.replace(
        'src="/ueditor/',
        'src="http://accountimg.haoyouzhushou.com/'
      );
    }

    // window.vue = this;

    _czc.push(["_trackEvent", "PUBG优选", "详情页面", "展现量"]);
  },
  methods: {
    // 游戏进度条弹窗
    // progressBarShow(num, text) {
    //   this.progressBarDialog = true;
    //   this.progressNum = num;
    //   this.progressText = text;
    // },
    // // 关闭进度条弹窗
    // progressBarClose() {
    //   this.progressBarDialog = false;
    //   this.progressNum = 0;
    //   this.progressText = "";
    // },
    async start() {
      // 是否登陆
      if (this.isLogin && this.gameDetail) {
        let phone = sessionStorage.getItem("Phone");
        // 是否绑定手机号
        if (phone) {
          // let gameId = this.gameDetail.ProductID;
          this.disabled = true;
          this.enable();

          // 实名认证开关
          if (this.getIsAuth) {
            // 是不是钻石网吧
            if (this.netBarVIP) {
              // 未绑定身份证或者非网吧系统 需要实名
              if (!this.userInfo.IDCard && !cMethods.FormIfNetBarSystem()) {
                eventBus.$emit("showAuth");
                return false;
              }
            } else {
              // 非钻石网吧  未绑定身份证 需要实名
              if (!this.userInfo.IDCard) {
                eventBus.$emit("showAuth");
                return false;
              }
            }

            if (this.userInfo && this.userInfo.IsNotMember) {
              // this.btn_disabled = false;
              // this.startGame(this.gameDetail, phone);
              this._getGameTime(phone);
            } else {
              if (this.netBarVIP == true) {
                this._GetNetbarOnlineNumber(phone);
              } else {
                this.isShowMemberDialog = true;
              }
            }
          } else {
            // 不需要实名认证
            if (this.userInfo && this.userInfo.IsNotMember) {
              this.startGame(this.gameDetail, phone);
            } else {
              if (this.netBarVIP == true) {
                this._GetNetbarOnlineNumber(phone);
              } else {
                this.isShowMemberDialog = true;
              }
            }
          }
        } else {
          // 绑定手机号弹窗
          eventBus.$emit("showLoginPhone");
        }
      } else {
        // 扫码登录弹窗
        eventBus.$emit("getQrCode");
      }

      _czc.push(["_trackEvent", "PUBG优选", "登录游戏按钮", "点击量"]);
    },
    // 10秒后解除按钮
    enable() {
      setTimeout(() => {
        this.disabled = false;
      }, 10000);
    },
    // 登录游戏
    startGame(item, phone) {
      this.Tel = phone;
      // ***直接上号  不区分存档游戏***
      this._GetGameAccountLogin(item);
    },
    // 获取网吧游戏配置时长
    async _GetNavigationList() {
      let params = {
        type: 7,
      };
      let res = await GetNavigationList(params);
      if (res.StatusCode == 200) {
        this.seniorGame = res.Object[0].Url;
      }
    },
    // 获取登录账号
    async _GetGameAccountLogin(item) {
      // 获取机器码
      let Machinecode = cMethods.FormGetMachineCode();

      if (Machinecode) {
        let params = {
          VipID: this.userInfo ? this.userInfo.ConsumerId : "",
          Tel: this.Tel,
          ProductID: item.ProductID,
          Machinecode: Machinecode,
          // IsVerifyVip: item.IsVipGame ? 0 : 1,
          netbarId: this.netBarId,
          netbarName: this.netBarName,
          isNotVerifyIDCard: !cMethods.FormIfNetBarSystem(),
          accountId: this.gameDetail.AccountID,
        };

        let res = await GetGameAccountLogin(params);

        if (res.StatusCode == 200) {
          this.$store.dispatch("_GetUserInfo");
          let json = JSON.parse(res.Object);
          if (json.Code == 0) {
            this.gameObjData = json.Data;
            // item.gameInfoEntity.Type: 2为单账号存档游戏  3为跨账号存档游戏
            if (item.gameInfoEntity && item.gameInfoEntity.Type == 3) {
              this._GetGameAccountList(item, this.gameObjData);
            } else {
              this._FormStartGame(this.gameObjData);
            }
          } else {
            // 当前游戏人数较多，请排队等待！
            this.errorStr = json.Message;
            this.dialogVisible = true;
            _czc.push([
              "_trackEvent",
              gameDetail.gameInfoEntity.Name,
              "点击",
              "取号失败",
            ]);
            // this.$message({
            //   message: json.Message,
            //   type: "error",
            // });
          }
        } else {
          this.$message({
            message: res.Message,
            type: "error",
          });
        }
      } else {
        this.$message({
          message: "获取机器码失败",
          type: "error",
        });
      }
    },
    // 获取钻石网吧当前可用名额
    async _GetNetbarOnlineNumber(phone) {
      let params = {
        netbarId: this.netBarId,
        phone: phone,
      };
      let res = await GetNetbarOnlineNumber(params);
      if (res.StatusCode == 200) {
        this.onlineNumber = res.Object;
        if (this.onlineNumber) {
          // this.btn_disabled = false;
          // 一键上号;
          // this.startGame(this.gameDetail, phone);
          this._getGameTime(phone);
          _czc.push(["_trackEvent", "游戏详情页面", "点击", "登录游戏"]);
        } else {
          this.isShowMemberDialog = true;
        }
      }
    },
    // 获取游戏存档
    async _GetGameAccountList(item, gameObj) {
      if (item && item.GameID) {
        let gameId = parseInt(item.GameID.substring(2, item.GameID.length));
        let params = {
          consumerId: this.userInfo ? this.userInfo.ConsumerId : "",
          gameId: gameId,
        };
        let res = await GetGameArchive(params);

        if (res.StatusCode == 200) {
          if (res.Items.length) {
            // 有存档
            res.Items.map((item) => {
              if (item.Type == 1) {
                this.perfectArchive = item;
              }
            });
            this.gameList = res.Items.filter((item) => item.Type != 1).slice(
              0,
              3
            );
            this.radio1 = "1";
            if (this.gameList.length) {
              this.ArchiveURL = this.gameList[0].GameArchiveInfoZipURL;
            }

            // this.gameObj = this.gameList[this.active];
            this.isShow = true;
          } else {
            // 没存档
            this._FormStartGame(gameObj);
          }
        } else {
          this.$message({
            message: res.Message,
            type: "error",
          });
        }
      }
    },
    // 绝地求生高端游戏账号启动
    seniorJDQS(phone) {
      this.disabled = true;
      this.enable();
      // if (this.userInfo && this.userInfo.IsNotMember) {
      // this.btn_disabled = false;
      // 一键上号;
      this.startGame(this.gameDetail, phone);
      _czc.push(["_trackEvent", "游戏详情页面", "点击", "登录游戏"]);
      // } else {
      //   if (this.netBarVIP == true) {
      //     this._GetNetbarOnlineNumber(phone);
      //   } else {
      //     this.isShowMemberDialog = true;
      //   }
      // }
    },
    // 精品号需在线游戏时长校验
    async _getGameTime(phone) {
      // 获取在线游戏时长
      let params = {
        phone: phone,
      };
      let res = await GetConsumerGameTimeByHaoYou(params);
      if (res.StatusCode == 200) {
        let timer = parseFloat(res.Object / 60).toFixed(1);
        this.$store.commit("setTimer", timer);

        if (parseInt(this.getTimer) < parseInt(this.seniorGame)) {
          this.gameTimeVisible = true;
        } else {
          this.$refs["authForGame"].show();
        }
      }
      _czc.push(["_trackEvent", "PUBG优选", "游戏时长不足弹窗", "展现量"]);
    },
    // 会员购买弹窗
    showDialogVisible(data, str) {
      if (data === "false") {
        this.isShowMemberDialog = false;
      } else {
        this.isShowMemberDialog = true;
      }
      if (str === "start") {
        if (this.gameDetail && this.gameDetail.gameInfoEntity) {
          this.$confirm(
            `是否确定启动《${this.gameDetail.gameInfoEntity.Name}》游戏？`,
            "提示：",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
            }
          )
            .then(() => {
              this.start();
            })
            .catch(() => { });
        }
      }
    },
    // 开通会员弹窗提示
    OpeningMmber() {
      this.$router.push("/members");
    },
    // 体验其他游戏
    toHome() {
      this.dialogVisible = false;
      this.$router.push("/boutique");
    },
    // 开始存档游戏
    startArchiveGame() {
      if (this.gameObjData) {
        this._FormStartGame(this.gameObjData);
      }
    },
    // 直接上号
    randomAccount() {
      if (this.gameObjData) {
        this.ArchiveURL = "";
        this._FormStartGame(this.gameObjData);
      }
    },
    // 上号器上号
    _FormStartGame(gameObj) {
      if (gameObj) {
        if (this.isShow) {
          this.isShow = false;
        }

        if (this.ArchiveURL) {
          gameObj.ArchiveURL = this.ArchiveURL;
        } else {
          gameObj.ArchiveURL = "";
        }
        // c++登录游戏
        cMethods.FormStartGameForHaoYou(
          this.userInfo.ConsumerId,
          gameObj.GameAccount,
          gameObj.Gamepwd,
          gameObj.GameName,
          gameObj.GameID,
          gameObj.IsImg,
          gameObj.WGID,
          gameObj.OrderID,
          gameObj.Answer,
          gameObj.Problem,
          gameObj.IsWG,
          gameObj.IsJC,
          gameObj.AccountBancheck,
          gameObj.ArchiveURL,
          gameObj.currentsteamgameid,
          this.gameDetail.gameInfoEntity &&
          this.gameDetail.gameInfoEntity.EnglishName,
          gameObj.Explanation,
          gameObj.IsGameSave,
          gameObj.Email,
          gameObj.Emailpwd,
          this.gameDetail.gameInfoEntity &&
          this.gameDetail.gameInfoEntity.Type,
          null,
          this.gameDetail.gameInfoEntity &&
          this.gameDetail.gameInfoEntity.gameProcessName
        );
      }
    },
    // 跳转绝地游戏
    toJdqs() {
      this.$router.push("/gameDetail/PC200420000002");
    },
  },
};
</script>

<style lang="scss" scoped>
.boutique-detail-wrap {
  .breadcrumb-box {
    .el-breadcrumb__item {
      font-size: 16px;
    }

    ::v-deep .el-breadcrumb__inner.is-link {
      font-size: 16px;
      font-weight: normal;
      @include font_col3();
    }
  }

  .game-info {
    margin-top: 20px;
    display: flex;
    @include item_bg_col();
    padding: 20px;

    .left {
      width: 433px;

      img {
        width: 433px;
        height: 243px;
      }
    }

    .right {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      flex: 1;
      @include font_col6();

      .title {
        font-size: 18px;
        font-weight: bold;
        @include font_col3();
        line-height: 28px;
        word-break: break-all;
      }

      .tags {
        display: flex;
        align-items: center;
        margin-top: 10px;

        span {
          padding: 0 10px;
          height: 28px;
          text-align: center;
          @include tag_item_bg_col();
          @include tag_item_col();
          border-radius: 14px;
          font-size: 14px;
          line-height: 28px;
          margin-right: 10px;
        }
      }

      // .introduction {
      //   margin-top: 10px;
      //   span {
      //     line-height: 24px;
      //     font-size: 15px;
      //     color: #000000;
      //   }
      // }
      .reminder {
        margin-top: 10px;

        span {
          line-height: 24px;
          font-size: 15px;
          color: $red-col;
        }
      }

      .careful {
        margin-top: 10px;

        span {
          line-height: 24px;
          font-size: 15px;
          color: $red-col;
        }
      }

      .btn {
        margin-top: 20px;
        text-align: right;

        .el-button {
          width: 160px;
          height: 40px;
          background: $red-col;
          color: #fff;
          border: none;
          font-size: 15px;
        }

        .dis {
          background: #ccc;
        }

        .occupy {
          color: $white-col;
          cursor: default;
          border: 1px solid #bcbcbc;
          background: #bcbcbc;

          &:hover {
            background: #bcbcbc;
          }
        }
      }
    }
  }

  .detail-content {
    margin-top: 20px;
    padding: 20px;
    @include item_bg_col();

    .title {
      font-size: 20px;
      font-weight: bold;
      @include font_col3();

      &::before {
        content: "";
        border-left: 4px solid #ff6c6c;
        padding-left: 10px;
      }
    }
  }
}

// 广告闪光效果
.pic-item {
  position: relative;
  overflow: hidden;
  display: block;
}

.pic-item:hover {
  transform: translateY(-5px);
  transition: 0.5s ease;
}

.pic-item:after {
  position: absolute;
  left: -100%;
  /*改变left的值，让其相对box影藏*/
  top: 0;
  width: 30%;
  height: 100%;
  content: "";
  /* Safari 5.1 - 6.0 */
  background: -webkit-linear-gradient(left,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.6) 50%,
      rgba(255, 255, 255, 0) 100%);
  /* Opera 11.1 - 12.0 */
  background: -o-linear-gradient(left,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.6) 50%,
      rgba(255, 255, 255, 0) 100%);
  /* Firefox 3.6 - 15 */
  background: -moz-linear-gradient(left,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.6) 50%,
      rgba(255, 255, 255, 0) 100%);
  /* 标准的语法 */
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.6) 50%,
      rgba(255, 255, 255, 0) 100%);
  transform: skewX(-45deg);
}

.pic-item:hover:after {
  left: 150%;
  transition: 1s ease;
}
</style>
<style lang="scss">
.html-content {
  margin-top: 20px;

  img {
    width: 100% !important;
  }
}

.progressBarDialog {
  border-radius: 8px;

  .el-dialog__header {
    padding: 0;
    border: none;
  }

  .el-dialog__body {
    padding: 20px;
  }

  .ctx {
    .gameName {
      margin-bottom: 10px;
      font-size: 18px;
      text-align: center;
    }

    .gameImg {
      margin-bottom: 20px;
    }

    .el-progress__text {
      margin-left: 10px;
    }

    p {
      margin-top: 10px;
      line-height: 20px;
    }
  }
}
</style>